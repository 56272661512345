<template>
  <div class="language-switcher">
    <tooltip-layout :max-width="160" ref="tooltipLayout" position="left">
      <template #icon>
        <div class="language-switcher__head">
          <div class="language-switcher__head-img">
            <img :src="`/img/locales/${$i18n.locale}.svg`" alt="" />
          </div>
        </div>
      </template>
      <template #content>
        <ul class="language-switcher__list">
          <li
            v-for="locale in languageList"
            :key="locale"
            v-show="locale !== $i18n.locale"
            @click.prevent="setI18nLanguage(locale)"
            class="language-switcher__item"
          >
            <span class="language-switcher__item-img">
              <img :src="`/img/locales/${locale}.svg`" alt="" />
            </span>
            <div class="language-switcher__item-title">
              {{ $t(`languages_${locale}`) }}
            </div>
          </li>
        </ul>
      </template>
    </tooltip-layout>
  </div>
</template>
<script setup>
import TooltipLayout from '@/components/atoms/TooltipLayout';

import {useI18n} from 'vue-i18n';
import {useRoute, useRouter} from 'vue-router';

const i18n = useI18n();
const router = useRouter();
const route = useRoute();

const setI18nLanguage = locale => {
  if (i18n.locale !== locale) {
    const {name} = router.currentRoute.value;
    router.replace({name: name, params: {...route.params, locale: locale}});
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
};

const languageList = i18n.availableLocales;
</script>
<style lang="scss">
.language-switcher {
  .tooltip-layout.open {
    .language-switcher__ico {
      transform: rotate(180deg);
    }
  }

  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    width: 30px;
    margin: 0;
    @include respondTo(800px) {
      width: 30px;
    }

    svg {
      transform: rotate(0);
      transition: fill $trans, transform $trans;
      width: 10px;
      color: $white;
      fill: $white;
      @include respondTo(450px) {
        width: 12px;
      }
    }

    &:hover {
      svg {
        fill: $black;
      }

      .language-switcher__current {
        color: $black;
      }
    }

    &-img {
      height: 20px;
      width: 30px;

      img {
        height: 20px;
        width: 30px;
      }
    }
  }

  &__current {
    margin-right: 5px;
    transition: color $trans;
    text-transform: uppercase;
    color: $white;
    font-size: 14px;
    @include respondTo(450px) {
      margin-left: 0;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  &__item {
    padding: 7px 10px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background $trans;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-img {
      img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }

    &-title {
      color: #000;
      text-decoration: none;
      font-size: 14px;
      line-height: 18px;
      width: calc(100% - 35px);
      overflow: hidden;
      text-transform: capitalize;
    }

    &:hover {
      background: $primary-light;
    }

    @include respondTo(450px) {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
}
</style>
