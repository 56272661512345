<template>
  <div class="tooltip-layout" v-click-away="close" :class="[position, {open: open}]">
    <div class="tooltip-layout__header">
      <div v-if="trigger === 'click'" class="tooltip-layout__header-toggle" @click.prevent.stop="open = !open">
        <slot name="icon"></slot>
      </div>
      <div v-if="trigger === 'hover'" class="tooltip-layout__header-toggle" @mouseenter.prevent.stop="open = !open">
        <slot name="icon"></slot>
      </div>
    </div>
    <transition name="slide-y-fade">
      <div class="tooltip-layout__wrap" :style="{width: `${maxWidth}px`}" v-show="open">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import router from '@/router';
import {ref} from 'vue';
import {mixin as VueClickAway} from 'vue3-click-away';

export default {
  name: 'TooltipLayout',
  mixins: [VueClickAway],
  props: {
    trigger: {
      type: String,
      default: 'click'
    },
    maxWidth: {
      type: Number,
      default: 200
    },
    position: {
      type: String,
      default: 'left'
    }
  },
  emits: ['on-close'],
  setup(_, {emit}) {
    const open = ref(false);
    const close = () => {
      open.value = false;
      emit('on-close');
    };

    router.beforeEach((to, from, next) => {
      if (open.value) {
        open.value = false;
      }
      next();
    });

    return {
      close,
      open
    };
  }
};
</script>
<style lang="scss">
.tooltip-layout {
  position: relative;
  &.right {
    .tooltip-layout__wrap {
      right: auto;
      left: 0;
    }
  }
  &.left {
    .tooltip-layout__wrap {
      right: 0;
      left: auto;
    }
  }
  &.bottom {
    .tooltip-layout__wrap {
      top: auto;
      bottom: calc(100% + 10px);
      left: auto;
      right: -12px;
    }
  }
  &__wrap {
    position: absolute;
    top: calc(100% + 10px);
    width: 200px;
    right: -4px;
    background: $white;
    border-radius: 3px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
    z-index: 5;
    padding: 10px 0;
    min-height: 40px;
    @include respondTo(500px) {
      padding-top: 5px;
      padding-bottom: 5px;
      max-width: 300px !important;
    }
  }
  &__header {
    &-toggle {
      cursor: pointer;
    }
  }
}
</style>
